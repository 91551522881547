import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, Text, colors } from '@orus.eu/pharaoh'
import type { NavigateOptions } from '@tanstack/react-router'
import { memo, type FunctionComponent } from 'react'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { usePermissions } from '../../../lib/use-permissions'
import { DataRow } from '../../molecules/backoffice-data-card'
import type { BackofficeUsage } from '../../pages/backoffice/common/backoffice-contract-page/contract-page-content'

export type ContractCardQuoteIdNumberRowsProps = {
  contract: BackofficeContractDescription
  type: BackofficeUsage
}

export const ContractCardQuoteIdNumberRows: FunctionComponent<ContractCardQuoteIdNumberRowsProps> = memo(
  function ContractCardQuoteIdNumberRows({ contract, type }) {
    const navigateUrl: NavigateOptions =
      type === 'platform'
        ? { to: '/bak/v2-pending-subscriptions/$subscriptionId', params: { subscriptionId: contract.subscriptionId } }
        : {
            to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
            params: {
              organization: contract.versions[0].dimensions.organization!,
              subscriptionId: contract.subscriptionId,
            },
          }
    const { permissions } = usePermissions()
    const navigateToContract = useNavigateTo(navigateUrl)

    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          ID de devis
        </Text>
        <Text variant="body2">{contract.subscriptionId}</Text>
        {permissions.includes('subscription.create') ? (
          <Button variant="secondary" size="small" onClick={navigateToContract}>
            Voir le devis
          </Button>
        ) : null}
      </DataRow>
    )
  },
)
