import type { Note } from '@orus.eu/backend/src/views/notes-view'
import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useState } from 'react'
import { trpc, trpcReact } from '../../../client'
import { LocalLoadingState } from '../../molecules/local-loading-state'
import { NotesBlock } from './notes-block'

export type InteractiveNotesBlockProps = {
  targetId: string
}

export function InteractiveNotesBlock({ targetId }: InteractiveNotesBlockProps): JSX.Element {
  const notesData = trpcReact.note.listNotes.useQuery(targetId)

  return notesData.data ? (
    <LoadedInteractiveNotesBlock targetId={targetId} initialNotes={notesData.data} />
  ) : (
    <LocalLoadingState />
  )
}

type LoadedInteractiveNotesBlockProps = {
  targetId: string
  initialNotes: Note[]
}

function LoadedInteractiveNotesBlock({ targetId, initialNotes }: LoadedInteractiveNotesBlockProps): JSX.Element {
  const [notes, setNotes] = useState(initialNotes)
  // updating notes on the server is really fast, so we don't have a loading / updating state

  const onNoteAdded = useAsyncCallback(
    async (content: string) => {
      await trpc.note.createNote.mutate({ targetId, content })
      setNotes(await trpc.note.listNotes.query(targetId))
    },
    [targetId],
  )

  const onNoteDeleted = useAsyncCallback(
    async (note: Note) => {
      await trpc.note.updateNote.mutate({ noteId: note.noteId, content: null })
      setNotes(await trpc.note.listNotes.query(targetId))
    },
    [targetId],
  )

  const onNoteUpdated = useAsyncCallback(
    async (noteId: string, newContent: string) => {
      await trpc.note.updateNote.mutate({ noteId, content: newContent })
      setNotes(await trpc.note.listNotes.query(targetId))
    },
    [targetId],
  )

  return (
    <NotesBlock notes={notes} onNoteAdded={onNoteAdded} onNoteDeleted={onNoteDeleted} onNoteUpdated={onNoteUpdated} />
  )
}
