import styled from '@emotion/styled'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, DownloadButtonWithHref, Text, colors, spacing, useLanguage } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { memo, type FunctionComponent } from 'react'
import { dynamicFileNames, staticFileNames } from '../../../lib/download-filenames'
import {
  getAdminCertificateDownloadLink,
  getAdminContractDownloadLink,
  getAdminScheduleDownloadLink,
  getPartnerCertificateDownloadLink,
  getPartnerContractDownloadLink,
  getPartnerScheduleDownloadLink,
} from '../../../lib/download-links'
import { usePermissions } from '../../../lib/use-permissions'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardSpecificDocumentsRowsProps = {
  contract: BackofficeContractDescription
  customerEmail: string
}

export const ContractCardSpecificDocumentsRow: FunctionComponent<ContractCardSpecificDocumentsRowsProps> = memo(
  function ContractCardSpecificDocumentsRows({ contract, customerEmail }) {
    const language = useLanguage()
    const { type: userType } = usePermissions()
    const { organization } = useParams({ strict: false })
    const { subscriptionId } = contract

    const downloadContractLink =
      userType === 'partner'
        ? getPartnerContractDownloadLink(subscriptionId, null, organization ?? '')
        : getAdminContractDownloadLink(subscriptionId, null)

    const certificateDownloadLink =
      userType === 'partner'
        ? getPartnerCertificateDownloadLink(subscriptionId, organization ?? '')
        : getAdminCertificateDownloadLink(subscriptionId)

    const scheduleDownloadLink =
      userType === 'partner'
        ? getPartnerScheduleDownloadLink(subscriptionId, organization ?? '')
        : getAdminScheduleDownloadLink(subscriptionId)

    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          Documents spécifiques
        </Text>
        <ButtonGroup>
          <DownloadButtonWithHref
            variant="secondary"
            size="small"
            href={downloadContractLink}
            fileName={dynamicFileNames['agreed-terms'](customerEmail, language)}
          />
          <DownloadButtonWithHref
            variant="secondary"
            size="small"
            href={certificateDownloadLink}
            fileName={dynamicFileNames['insurance-certificate'](customerEmail, language)}
          />
          <ScheduleDownloadButton contract={contract} scheduleDownloadLink={scheduleDownloadLink} />
        </ButtonGroup>
      </DataRow>
    )
  },
)

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[40]};
  margin: ${spacing[40]};
`

type ScheduleDownloadButtonProps = {
  contract: BackofficeContractDescription
  scheduleDownloadLink: string
}
const ScheduleDownloadButton: FunctionComponent<ScheduleDownloadButtonProps> = memo(function ScheduleDownloadButton({
  contract,
  scheduleDownloadLink,
}) {
  const language = useLanguage()
  const { versions } = contract

  if (versions.length > 1) {
    return (
      <Button
        variant="tertiary"
        size="small"
        disabled
        icon="file-pdf-solid"
        title="L'échéancier ne peut être généré automatiquement car il y a des avenants"
      >
        Échéancier de paiement
      </Button>
    )
  }
  return (
    <DownloadButtonWithHref
      variant="secondary"
      size="small"
      href={scheduleDownloadLink}
      fileName={staticFileNames['payment-schedule'](language)}
    />
  )
})
