import { css } from '@emotion/react'
import { Avatar, Button, spacing, Text, useAsyncCallback } from '@orus.eu/pharaoh'
import { memo, useCallback, useState } from 'react'
import { type ValidationResult } from '../../lib/validation'
import { ValidatedTextField } from '../molecules/validated-text-field'

export type BackofficeEditableFieldProps<VALUE extends string> = {
  value: VALUE
  handleUpdate: (value: VALUE) => Promise<void>
  validator: (value: string) => ValidationResult<VALUE>
  /**
   * Name of the edited field, used to prefix the aria label of the buttons
   */
  fieldName: string
  inputType?: 'text' | 'email' | 'tel'
}

export const BackofficeEditableField = memo(function BackofficeEditableField<VALUE extends string>({
  handleUpdate,
  validator,
  value,
  fieldName,
  inputType,
}: BackofficeEditableFieldProps<VALUE>) {
  const [mode, setMode] = useState<'displaying' | 'editing' | 'updating'>('displaying')
  const [draftValue, setDraftValue] = useState<VALUE | null>(value)

  const validateEnabled = mode === 'editing' && draftValue != null

  const handleCancelClick = useCallback(() => {
    setMode('displaying')
  }, [])

  const handleValidateClick = useAsyncCallback(async () => {
    if (!validateEnabled) return
    setMode('updating')
    await handleUpdate(draftValue)
    setMode('displaying')
  }, [handleUpdate, validateEnabled, draftValue])

  const handleModifyClick = useCallback(() => {
    setMode('editing')
  }, [])

  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing[50]};
        align-items: center;
      `}
    >
      {mode === 'editing' || mode === 'updating' ? (
        <>
          <ValidatedTextField
            type={inputType}
            autoFocus
            disabled={mode === 'updating'}
            initialValue={value}
            onChange={setDraftValue}
            validator={validator}
          />
          <Button
            ariaLabel={`discard-${fieldName}-changed`}
            size="small"
            disabled={mode === 'updating'}
            variant="secondary"
            onClick={handleCancelClick}
            avatar={<Avatar icon="arrow-rotate-left-solid" />}
          />
          <Button
            ariaLabel={`validate-${fieldName}-changes`}
            size="small"
            disabled={!validateEnabled}
            variant="primary"
            onClick={handleValidateClick}
            avatar={<Avatar icon="check-solid" />}
          />
        </>
      ) : (
        <>
          <Text variant="body2">{value}</Text>
          <Button
            ariaLabel={`edit-${fieldName}`}
            size="small"
            onClick={handleModifyClick}
            variant="secondary"
            avatar={<Avatar icon="pen-regular" />}
          />
        </>
      )}
    </div>
  )
})
