import { rateToPercentString } from '@orus.eu/amount'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Text, colors } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardDiscountProps = {
  contract: BackofficeContractDescription
}

export const ContractCardDiscount: FunctionComponent<ContractCardDiscountProps> = memo(
  function ContractCardCommitmentDuration({ contract }) {
    const text = contract.discount
      ? `${contract.discount.code} (-${rateToPercentString(contract.discount.rate, 0)})`
      : 'Aucun'

    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          Discount
        </Text>
        <Text variant="body2">{text}</Text>
      </DataRow>
    )
  },
)
