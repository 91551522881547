import { amountToString, gt, zeroAmount, type AmountToStringOptions } from '@orus.eu/amount'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Text, colors } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardPartnerRowProps = {
  contract: BackofficeContractDescription
}

export const ContractCardPartnerRow: FunctionComponent<ContractCardPartnerRowProps> = memo(
  function ContractCardCommitmentDuration({ contract }) {
    const partnerApplicationFeeText =
      contract.quote.quote.partnerApplicationPremium && gt(contract.quote.quote.partnerApplicationPremium, zeroAmount)
        ? `${amountToString(contract.quote.quote.partnerApplicationPremium, amountToStringOptions)}`
        : 'Aucun'

    const yearlyPartnerManagementFeeText =
      contract.quote.quote.yearlyPartnerManagementPremium &&
      gt(contract.quote.quote.yearlyPartnerManagementPremium, zeroAmount)
        ? `${amountToString(contract.quote.quote.yearlyPartnerManagementPremium, amountToStringOptions)}`
        : 'Aucun'

    return (
      <div>
        <DataRow>
          <Text variant="body2" color={colors.gray[700]}>
            Frais de dossier
          </Text>
          <Text variant="body2">{partnerApplicationFeeText}</Text>
        </DataRow>
        <DataRow>
          <Text variant="body2" color={colors.gray[700]}>
            Frais de gestion (par an)
          </Text>
          <Text variant="body2">{yearlyPartnerManagementFeeText}</Text>
        </DataRow>
      </div>
    )
  },
)

const amountToStringOptions: AmountToStringOptions = {
  addCurrency: true,
  displayDecimals: true,
}
