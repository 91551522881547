import { css } from '@emotion/react'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Text, colors, spacing } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { ActivableProductInformationIdsBadgesList } from '../../molecules/activable-product-information-ids-badges-list'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardActivityRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardActivityRow: FunctionComponent<ContractCardActivityRowsProps> = memo(
  function ContractCardContractNumberRows({ contract }) {
    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          Police et activité
        </Text>
        <div
          css={css`
            display: flex;
            gap: ${spacing[50]};
          `}
        >
          <ActivableProductInformationIdsBadgesList
            activableProductInformationIds={contract.activableProductInformationIds}
          />
          <Text variant="body2">{contract.activity.displayName}</Text>
        </div>
      </DataRow>
    )
  },
)
