import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { Commitment } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Text, colors } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardCommitmentDurationProps = {
  contract: BackofficeContractDescription
}

export const ContractCardCommitmentDuration: FunctionComponent<ContractCardCommitmentDurationProps> = memo(
  function ContractCardCommitmentDuration({ contract }) {
    const latestVersion = checkDefinedAndNotNull(contract.versions.at(-1))
    const commitment = checkDefinedAndNotNull(latestVersion.dimensions.commitment)

    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          Durée d’engagement
        </Text>
        <Text variant="body2">{commitmentLabel[commitment]}</Text>
      </DataRow>
    )
  },
)

const commitmentLabel: Record<Commitment, string> = {
  monthly: '1 mois',
  yearly: '1 an',
}
