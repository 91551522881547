import { CheckboxContainer, useAsyncCallback } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpc, trpcReact } from '../../client'

export const BackofficeInvoicingEmailCheckbox = memo<{ userId: string }>(function BackofficeInvoicingEmailCheckbox({
  userId,
}) {
  const getUserNotificationsConfigurationApiCall =
    trpcReact.notificationsConfiguration.getUserNotificationsConfiguration.useQuery(userId)

  const configuration = getUserNotificationsConfigurationApiCall.data
  const checked = configuration?.sendInvoiceEmail ?? false

  const handleChange = useAsyncCallback(
    async (value: boolean) => {
      if (!configuration) return
      await trpc.notificationsConfiguration.updateUserNotificationConfiguration.mutate({
        userId,
        notificationsConfiguration: { ...configuration, sendInvoiceEmail: value },
      })
      await getUserNotificationsConfigurationApiCall.refetch()
    },
    [configuration, getUserNotificationsConfigurationApiCall, userId],
  )

  const loading = !getUserNotificationsConfigurationApiCall.data

  return (
    <CheckboxContainer size="small" checked={checked} onChange={handleChange} disabled={loading}>
      Envoi des factures par email
    </CheckboxContainer>
  )
})
