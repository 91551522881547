import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { ButtonLink } from '@orus.eu/pharaoh'
import { orusOrganization } from '@orus.eu/right-access-management'
import type { NavigateOptions } from '@tanstack/react-router'
import { memo, type FunctionComponent } from 'react'
import { BackofficeDataCard, DataList } from '../../molecules/backoffice-data-card'
import type { BackofficeUsage } from '../../pages/backoffice/common/backoffice-contract-page/contract-page-content'
import { ContractCardActivityRow } from './contract-card-activity-row'
import { ContractCardAddressRow } from './contract-card-address-row'
import { ContractCardCommitmentDuration } from './contract-card-commitment-duration'
import { ContractCardContractNumberRow } from './contract-card-contract-number-row'
import { ContractCardDiscount } from './contract-card-discount'
import { ContractCardGenericDocumentsRow } from './contract-card-generic-documents-row'
import { ContractCardNotesRow } from './contract-card-notes-row'
import { ContractCardOldContractTerminationRow } from './contract-card-old-contract-termination-row'
import { ContractCardOptions } from './contract-card-options-row'
import { ContractCardPersonalAddressRow } from './contract-card-personal-address-row'
import { ContractCardQuoteIdNumberRows } from './contract-card-quote-id-row'
import { ContractCardSignatureRow } from './contract-card-signature-row'
import { ContractCardSirenRow } from './contract-card-siren-row'
import { ContractCardSpecificDocumentsRow } from './contract-card-specific-documents-row'
import { ContractCardStartRow } from './contract-card-start-row'
import { ContractCardStatusRow } from './contract-card-status-row'
import { ContractCardPrice } from './contract-cart-price-row'
import { ContractCardPartnerRow } from './ContractCardPartnerRow'

type ContractCardProps = {
  contract: BackofficeContractDescription
  showContractLink?: boolean
  customerEmail: string
  isSummary?: boolean
  type: BackofficeUsage
}

export const BackofficeContractCard: FunctionComponent<ContractCardProps> = memo(function ContractCard({
  contract,
  showContractLink,
  customerEmail,
  isSummary = false,
  type,
}) {
  const href: NavigateOptions =
    type === 'platform'
      ? { to: '/bak/contracts/$subscriptionId', params: { subscriptionId: contract.subscriptionId } }
      : {
          to: '/partner/$organization/contracts/$subscriptionId',
          params: {
            organization: contract.versions[0].dimensions.organization!,
            subscriptionId: contract.subscriptionId,
          },
        }

  const contractLink = showContractLink ? (
    <ButtonLink size="small" variant="secondary" icon="arrow-right-regular" {...href}>
      Ouvrir le contrat
    </ButtonLink>
  ) : (
    <></>
  )

  return (
    <BackofficeDataCard
      icon="folder-regular"
      title={`${contract.displayName} — ${contract.contractNumber}`}
      headerLink={contractLink}
    >
      <DataList>
        <ContractCardSirenRow contract={contract} />
        {!isSummary ? (
          <>
            <ContractCardContractNumberRow contract={contract} />
            <ContractCardQuoteIdNumberRows contract={contract} type={type} />
          </>
        ) : null}
        <ContractCardActivityRow contract={contract} />
        <ContractCardStatusRow contract={contract} />
        <ContractCardSignatureRow contract={contract} />
        <ContractCardStartRow contract={contract} />
        {!isSummary ? (
          <>
            <ContractCardCommitmentDuration contract={contract} />
            <ContractCardDiscount contract={contract} />
            {contract.versions[0].dimensions.organization !== orusOrganization ? (
              <ContractCardPartnerRow contract={contract} />
            ) : null}
            <ContractCardPrice contract={contract} />
            <ContractCardOptions contract={contract} />
            <ContractCardOldContractTerminationRow contract={contract} />
            <ContractCardSpecificDocumentsRow contract={contract} customerEmail={customerEmail} />
            <ContractCardGenericDocumentsRow contract={contract} />
            <ContractCardAddressRow contract={contract} />
            <ContractCardPersonalAddressRow contract={contract} />
            {type === 'platform' ? <ContractCardNotesRow contract={contract} /> : undefined}
          </>
        ) : null}
      </DataList>
    </BackofficeDataCard>
  )
})
