import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, Text, colors } from '@orus.eu/pharaoh'
import { getProtectionStatusAtTime } from '@orus.eu/protection'
import { memo, type FunctionComponent } from 'react'
import { getContractStatusUiProps } from '../../../lib/contract-util'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { usePermissions } from '../../../lib/use-permissions'
import { DataRow } from '../../molecules/backoffice-data-card'
import { Tag } from '../../molecules/tag'

export type ContractCardStatusRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardStatusRow: FunctionComponent<ContractCardStatusRowsProps> = memo(
  function ContractCardStatusRow({ contract }: ContractCardStatusRowsProps): JSX.Element {
    const { type: userType, permissions } = usePermissions()
    const isPlatform = userType === 'platform'

    const navigateToContract = useNavigateTo({
      to: '/bak/contracts/$subscriptionId/status',
      params: { subscriptionId: contract.subscriptionId },
    })
    const currentTimestamp = useCurrentTimestamp(oneMinute)
    const protectionStatusSchedule = getProtectionStatusAtTime(contract.protectionHistory, currentTimestamp)

    const { icon, color, label, comment } = getContractStatusUiProps(protectionStatusSchedule)
    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          Statut
        </Text>
        <span>
          <Tag variant={'colorScale'} icon={icon} colorScale={color} text={label} />
        </span>
        {comment ? <Text variant="body2">{comment}</Text> : <></>}
        {isPlatform && permissions.includes('endorsement.create') ? (
          <Button variant="secondary" size="small" onClick={navigateToContract}>
            Modifier le statut
          </Button>
        ) : (
          <></>
        )}
      </DataRow>
    )
  },
)
