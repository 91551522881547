import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Text, colors } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardContractNumberRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardContractNumberRow: FunctionComponent<ContractCardContractNumberRowsProps> = memo(
  function ContractCardContractNumberRows({ contract }) {
    return (
      <DataRow>
        <Text variant="body2" color={colors.gray[700]}>
          Numéro de contrat
        </Text>
        <Text variant="body2">{contract.contractNumber}</Text>
      </DataRow>
    )
  },
)
